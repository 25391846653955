import React, { Dispatch, SetStateAction, useState } from 'react';
import {
	createStyles,
	Paper,
	Table,
	Text,
	Menu,
	CopyButton,
	Checkbox,
	ActionIcon,
	Group,
	Tooltip,
	Progress,
	UnstyledButton,
	Center,
	Loader,
	rem,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {} from '@fortawesome/pro-light-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';
import { IMeeting } from '../../interfaces/meeting';
import { AttachmentsModal } from '../AttachmentsModal';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	currentMeeting,
	currentMeetingDistributionList,
	editScheduledMeetingInitialValues,
	editScheduledMeetingModalOpen,
	meetingSegmentedControlValue,
	meetingVideoUploadProgressBars,
	previousPageURL,
	reloadMeetingsAtom,
	uploadingVideoAtom,
} from '../../Atoms/meetingAtom';
import { StatusBadge } from '../Badges/StatusBadge';
import { capitalizeFirstLetter } from '../../_utils/handy-functions';
import { RowData } from './helper';
import Introduction from '../../pages/meetings/components/Introduction';
import {
	SpecialCollections,
	addMeetingToCollectionModalOpenedAtom,
	archiveCollectionAtom,
	bulkDeletingCollectionMeetingsSelection,
	currentCollection,
	meetingBeingAddedToCollection,
} from '../../Atoms/collections';
import { useFlags } from 'flagsmith/react';
import { flagsmithFeatureFlags } from '../constants';
import {
	IconSelector,
	IconChevronDown,
	IconChevronUp,
} from '@tabler/icons-react';
import classes from './MeetingTable.module.css';
import { hasCalendar } from '../../Atoms/integrations';
import TableRowSkeleton from '../Skeletons/TableRowSkeleton';
import { useCollections } from '../../customHooks/useCollections';
import { currentUser } from '../../Atoms/userAtoms';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import { logger } from '../../helpers/logger';
import {
	faBoxArchive,
	faTrash,
	faPaperclip,
	faEllipsisVertical,
	faLink,
	faPlus,
	faEdit,
	faFileInvoice,
	faShare,
	faUserRobot,
} from '@fortawesome/pro-regular-svg-icons';
import { isMeetingOwnerOrProjectManager } from '../../helpers/auth';
import {
	destroyMeeting,
	getMeetingInvitedUsers,
	updateMeeting,
} from '../../api/api';
import { organizationSettings } from '../../Atoms/settings';
import { BotStatus } from '../../interfaces/recallai';
import {
	removeBotFromCalendarEvent,
	scheduleBotForCalendarEvent,
} from '../../api/calendar';
import {
	upcomingMeetings,
	updateMeetingLibraryToggleAtom,
} from '../../Atoms/recallAI';
import { getMeetingHost } from '../../helpers/meetings';
import { formatDeadlineTime } from '../../helpers/date';

export const VIDEO_UPLOAD_COMPLETED_PERCENTAGE = 100;

const enabledMeetingStatuses = ['review', 'published'];
const isAbleToOpenMeeting = (status: string) =>
	enabledMeetingStatuses.includes(status);

interface ThProps {
	children: React.ReactNode;
	reversed: boolean;
	sorted: boolean;
	onSort(): void;
}

function Th({ children, reversed, sorted, onSort }: ThProps) {
	const Icon = sorted
		? reversed
			? IconChevronUp
			: IconChevronDown
		: IconSelector;
	return (
		<th className={classes.th}>
			<UnstyledButton onClick={onSort} className={classes.control}>
				<Group position='apart' noWrap>
					<Text fw={500} fz='sm'>
						{children}
					</Text>
					<Center className={classes.icon}>
						<Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
					</Center>
				</Group>
			</UnstyledButton>
		</th>
	);
}

const useStyles = createStyles(() => ({
	header: {
		height: '45px',
		alignContent: 'center',
	},
	paperClip: {
		cursor: 'pointer',
	},
}));

interface Props {
	meetings: IMeeting[];
	setMeetings: Dispatch<SetStateAction<IMeeting[]>>;
	search: string;
	rowsPerPage: number;
	meetingCount: number;
	fetchingMeetings: boolean;
	changeSort?: (value: string) => void;
	isCollections?: boolean;
	sortData?: (data: any, payload: any) => any;
	setSortedData?: (value: any) => void;
	collectionMeetings?: IMeeting[];
	collectionSortBy?: keyof RowData;
	collectionSetSortBy?: Dispatch<SetStateAction<keyof RowData>>;
	collectionSetReverseSortDirection?: (value: boolean) => void;
	collectionReverseSortDirection?: boolean;
	segmentValueProp?: string;
	openSharingModal?: () => void;
}

export default function MeetingTable({
	meetings,
	setMeetings,
	search,
	meetingCount,
	fetchingMeetings,
	rowsPerPage,
	changeSort,
	sortData,
	setSortedData,
	collectionSortBy,
	collectionSetSortBy,
	collectionSetReverseSortDirection,
	isCollections = false,
	collectionMeetings = [],
	collectionReverseSortDirection,
	segmentValueProp,
	openSharingModal,
}: Props) {
	const { classes } = useStyles();
	const location = useLocation();
	const flags = useFlags([flagsmithFeatureFlags.meetingCollections]);
	const { sharing: sharingEnabled } = useRecoilValue(organizationSettings);
	const collectionsEnabled = flags.meeting_collections.enabled;
	const isOnCollectionsPage = location.pathname.includes('collections');
	const user = useRecoilValue(currentUser);
	const [menuLoadingIndex, setMenuLoadingIndex] = useState<null | number>(null);
	const collection = useRecoilValue(currentCollection);
	const isCollectionOwner = collection?.owningUserID === user.id;
	const { removeMeetingFromCollection, addMeetingsToCollection } =
		useCollections(false);
	const [viewingAttachments, setViewingAttachments] = useState(false);
	const [viewingMeeting, setViewingMeeting] = useState<IMeeting | null>(null);
	const calendarIntegrated = useRecoilValue(hasCalendar);
	const videoUploadProgressBars = useRecoilValue(
		meetingVideoUploadProgressBars
	);
	const segmentValue = useRecoilValue(meetingSegmentedControlValue);
	const [selection, setSelection] = useRecoilState(
		bulkDeletingCollectionMeetingsSelection
	);
	const setAddMeetingToCollectionModalOpened = useSetRecoilState(
		addMeetingToCollectionModalOpenedAtom
	);
	const setMeetingToAdd = useSetRecoilState(meetingBeingAddedToCollection);
	const uploadingVideo = useRecoilValue(uploadingVideoAtom);
	const setEditScheduledMeetingModalOpen = useSetRecoilState(
		editScheduledMeetingModalOpen
	);
	const setEditScheduledMeetingInitialValues = useSetRecoilState(
		editScheduledMeetingInitialValues
	);
	const setReloadMeetings = useSetRecoilState(reloadMeetingsAtom);
	const setMeeting = useSetRecoilState(currentMeeting);
	const setDistributionList = useSetRecoilState(currentMeetingDistributionList);
	const setPreviousURL = useSetRecoilState(previousPageURL);

	const archiveCollection = useRecoilValue(archiveCollectionAtom);
	const viewingArchived = collection?.name === SpecialCollections.Archived;

	// table sort
	const [sortBy, setSortBy] = useState<keyof RowData | null>(null);
	const [reverseSortDirection, setReverseSortDirection] = useState(false);

	const [upcomingMeetingsState, setUpcomingMeetings] =
		useRecoilState(upcomingMeetings);
	const setUpdateMeetingLibraryToggle = useSetRecoilState(
		updateMeetingLibraryToggleAtom
	);

	// this should refire searchMeetings and update the "order" param accordingly.
	const setSorting = (field: keyof RowData) => {
		if (isCollections) {
			const reversed =
				field === collectionSortBy ? !collectionReverseSortDirection : false;
			collectionSetReverseSortDirection(reversed);
			collectionSetSortBy(field);
			setSortedData(
				sortData(collectionMeetings, { sortBy: field, reversed, search })
			);
		} else {
			const reversed = field === sortBy ? !reverseSortDirection : false;
			setReverseSortDirection(reversed);
			setSortBy(field);
			// %2B is "+" in URL encoding, and "-" is "%2D
			const order = `${reversed ? '-' : '%2B'}${field}`;
			changeSort(order);
		}
	};

	const openAttachmentModal = (meeting: IMeeting) => {
		setViewingAttachments(true);
		setViewingMeeting(meeting);
	};

	const handleUpdateMeeting = (meeting: IMeeting) => {
		const { name, id, botMetadata } = meeting;
		// open edit meeting modal
		setEditScheduledMeetingModalOpen(true);
		// set initial values to prefill form.
		setEditScheduledMeetingInitialValues({
			name: name,
			meetingLink: botMetadata?.meetingLink,
			joinAt: botMetadata?.joinAt,
			meetingID: id,
			botID: botMetadata?.id,
		});
	};

	const toggleRow = (id: string) =>
		setSelection((current) =>
			current.includes(id)
				? current.filter((item) => item !== id)
				: [...current, id]
		);
	const toggleAll = () =>
		setSelection((current) =>
			current.length === meetings.length ? [] : meetings.map((item) => item.id)
		);

	const handleArchiveMeeting = async (meeting: IMeeting, index: number) => {
		try {
			const { id } = meeting;
			setMenuLoadingIndex(index);
			await addMeetingsToCollection(archiveCollection.id, [id]);
			if (setReloadMeetings) {
				setReloadMeetings((prev: any) => !prev);
			}
			showSuccessNotification({
				message: `Meeting was successfully archived.`,
			});
		} catch (err) {
			logger('error', 'Error archiving meeting', err);
			showFailureNotification({
				message: `Apologies, the meeting was not successfully archived. Please try the operation again.`,
			});
		} finally {
			setMenuLoadingIndex(null);
		}
	};

	const handleUnarchiveMeeting = async (meeting: IMeeting, index: number) => {
		try {
			const { id } = meeting;
			setMenuLoadingIndex(index);
			await removeMeetingFromCollection(archiveCollection, id, false);
			showSuccessNotification({
				message: `Meeting was successfully unarchived.`,
			});
			if (setReloadMeetings) {
				setReloadMeetings((prev: any) => !prev);
			}
		} catch (err) {
			logger('error', 'Error archiving meeting', err);
			showFailureNotification({
				message: `Apologies, the meeting was not successfully archived. Please try the operation again.`,
			});
		} finally {
			setMenuLoadingIndex(null);
		}
	};

	const handleRecapOnly = async ({
		recapOnly,
		index,
		meetingID,
		organizationID,
	}: {
		recapOnly: boolean;
		index: number;
		meetingID: string;
		organizationID: string;
	}) => {
		try {
			setMenuLoadingIndex(index);
			const updatedMeeting = await updateMeeting(
				{
					recapOnly: !recapOnly,
				},
				meetingID,
				organizationID
			);
			if (setReloadMeetings) setReloadMeetings((prev: any) => !prev);
		} catch (error) {
			showFailureNotification({
				message:
					'Sorry, we encountered an issue while trying to update the meeting. Please give it another try.',
			});
			logger('error', 'error updating meeting:', error);
		} finally {
			setMenuLoadingIndex(null);
		}
	};

	const handleBotJoin = async ({
		index,
		botDontJoin,
		botMetadata,
		id,
		organizationID,
	}) => {
		try {
			setMenuLoadingIndex(index);
			if (botDontJoin) {
				// add bot to calendar event
				const res = await scheduleBotForCalendarEvent(
					botMetadata?.recallEventID,
					id,
					organizationID
				);
				const newBotMetadata = res?.data?.data;
				setMeetings((prev) =>
					prev.map((m) => {
						if (m.id === id) {
							return {
								...m,
								botMetadata: newBotMetadata || m.botMetadata,
							};
						}
						return m;
					})
				);
				showSuccessNotification({
					message: `Bot was successfully scheduled for this meeting.`,
				});
			} else {
				const botRes = await removeBotFromCalendarEvent(
					botMetadata.recallEventID,
					id,
					organizationID,
					botMetadata.eventID
				);
				if (botRes.status === 200) {
					showSuccessNotification({
						message: 'Bot was successfully removed from this meeting.',
					});
					const botMetadata = botRes?.data?.data?.botMetadata;
					setUpcomingMeetings((prev) =>
						prev.map((m) =>
							m.id === id
								? { ...m, botMetadata: botMetadata || m.botMetadata }
								: m
						)
					);
					setMeetings((prev) =>
						prev.map((m) =>
							m.id === id
								? { ...m, botMetadata: botMetadata || m.botMetadata }
								: m
						)
					);
					setTimeout(() => {
						setUpdateMeetingLibraryToggle((prev) => !prev);
					}, 1);
				}
			}
		} catch (error) {
			showFailureNotification({
				message:
					'Sorry, we encountered an issue while trying to update the meeting. Please give it another try.',
			});
			logger('error', 'error updating meeting:', error);
		} finally {
			setMenuLoadingIndex(null);
		}
	};

	const handleDelete = async (meetingID: string, organizationID: string) => {
		try {
			// delete meeting
			const res = await destroyMeeting(meetingID, organizationID);
			setReloadMeetings((prev: any) => !prev);
			showSuccessNotification({
				message: 'Meeting successfully deleted.',
			});
		} catch (error) {
			showFailureNotification({
				message:
					'Sorry, we encountered an issue while trying to delete the meeting. Please give it another try.',
			});
			logger('error', 'error deleting meeting:', error);
		}
	};

	const handleShare = async (meeting: IMeeting, index: number) => {
		try {
			const { id, organizationID } = meeting;
			setMenuLoadingIndex(index);
			setMeeting(meeting);
			const res = await getMeetingInvitedUsers(id, organizationID);
			const invitedUsers = res?.data?.data?.invitedUsers || [];
			const filteredDistributionList = invitedUsers.filter(
				(user) => !user?.userlink?.presented
			);
			setDistributionList(filteredDistributionList);
			openSharingModal();
		} catch (err) {
			logger('error', 'Error sharing meeting', err);
			showFailureNotification({
				message: `Apologies, the meeting was not successfully shared. Please try the operation again.`,
			});
		} finally {
			setMenuLoadingIndex(null);
		}
	};

	const reverseSortDirectionUsed = isCollections
		? collectionReverseSortDirection
		: reverseSortDirection;
	const sortByToUse = isCollections ? collectionSortBy : sortBy;

	const ths = (
		<tr>
			<th>
				<Checkbox
					onChange={toggleAll}
					checked={selection.length === meetings.length && !fetchingMeetings}
					indeterminate={
						selection.length > 0 && selection.length !== meetings.length
					}
					size={'xs'}
				/>
			</th>
			<Th
				sorted={sortByToUse === 'name'}
				reversed={reverseSortDirectionUsed}
				onSort={() => setSorting('name')}
			>
				Meeting Name
			</Th>
			<Th
				sorted={sortByToUse === 'host'}
				reversed={reverseSortDirectionUsed}
				onSort={() => setSorting('host')}
			>
				Hosted By
			</Th>
			<Th
				sorted={sortByToUse === 'viewingDeadline'}
				reversed={reverseSortDirectionUsed}
				onSort={() => setSorting('viewingDeadline')}
			>
				Deadline
			</Th>
			<Th
				sorted={sortByToUse === 'meetingDate'}
				reversed={reverseSortDirectionUsed}
				onSort={() => setSorting('meetingDate')}
			>
				Meeting Date
			</Th>
			<Th
				sorted={sortByToUse === 'status'}
				reversed={reverseSortDirectionUsed}
				onSort={() => setSorting('status')}
			>
				Status
			</Th>
			<th>Action Items</th>
			<th />
			{uploadingVideo && <th>Video Upload</th>}
			<Th
				sorted={sortByToUse === 'platform'}
				reversed={reverseSortDirectionUsed}
				onSort={() => setSorting('platform')}
			>
				Platform
			</Th>
			<Th
				sorted={sortByToUse === 'recapOnly'}
				reversed={reverseSortDirectionUsed}
				onSort={() => setSorting('recapOnly')}
			>
				Recap only
			</Th>
		</tr>
	);

	const rows = meetings.map((meeting: IMeeting, index) => {
		const {
			id,
			organizationID,
			status,
			owningUserID,
			recapOnly,
			platform,
			botMetadata,
			meetingDate,
			startAt,
			actionsCount,
			attachmentsCount,
			viewingDeadline,
			name,
			author,
			organizer,
			friendlyID,
		} = meeting;
		const isArchived = archiveCollection?.content.some(
			(c) => c.meetingID === id
		);
		const isMeetingHostOrPM = isMeetingOwnerOrProjectManager(
			organizer,
			owningUserID,
			user.id,
			user.roles
		);

		const isScheduledMeeting = status === 'scheduled';
		const isCalendarEvent = Boolean(botMetadata?.recallEventID);
		const botDontJoin = botMetadata?.status === BotStatus.BotDeleted;
		const meetingTime = meetingDate || startAt || botMetadata?.joinAt;
		const hostName = getMeetingHost(organizer, author);

		return (
			<tr key={id}>
				<td>
					<Checkbox
						size={'xs'}
						checked={selection.includes(id)}
						onChange={() => toggleRow(id)}
					/>
				</td>
				<td>
					{isAbleToOpenMeeting(status) ? (
						<Link
							to={`/meetings/${friendlyID}`}
							onClick={() => setPreviousURL(location.pathname)}
							style={{ color: 'black', textDecoration: 'none' }}
						>
							<Text weight={600}>{name}</Text>
						</Link>
					) : (
						<Text weight={600}>{name}</Text>
					)}
				</td>
				<td> {hostName}</td>
				<td>{viewingDeadline ? formatDeadlineTime(viewingDeadline) : 'N/A'}</td>
				<td>{meetingTime ? formatDeadlineTime(meetingTime) : 'N/A'}</td>
				<td>
					<StatusBadge status={status} botStatus={botMetadata.status} />
				</td>
				<td>{actionsCount}</td>
				<td>
					{attachmentsCount > 0 &&
						(status === 'published' || status === 'planned') && (
							<Tooltip label={attachmentsCount}>
								<FontAwesomeIcon
									onClick={() => openAttachmentModal(meeting)}
									className={classes.paperClip}
									icon={faPaperclip}
								/>
							</Tooltip>
						)}
				</td>

				{/*Video Upload Progress Bar*/}
				{uploadingVideo && (
					<td>
						{(status === 'draft' || videoUploadProgressBars[id]) && (
							<>
								{videoUploadProgressBars[id] ? (
									+videoUploadProgressBars[id]?.toFixed(0) ===
										VIDEO_UPLOAD_COMPLETED_PERCENTAGE || status === 'draft' ? (
										<>successful</>
									) : (
										<>
											<Progress
												color='violet'
												value={+videoUploadProgressBars[id]?.toFixed(0)}
												animate={
													+videoUploadProgressBars[id]?.toFixed(0) !==
													VIDEO_UPLOAD_COMPLETED_PERCENTAGE
												}
											/>
										</>
									)
								) : (
									<div></div>
								)}
							</>
						)}
					</td>
				)}
				<td>
					{typeof platform === 'string'
						? capitalizeFirstLetter(platform)
						: 'N/A'}
				</td>
				<td>{recapOnly ? 'Yes' : 'No'}</td>
				<td>
					{botMetadata?.meetingLink && (
						<CopyButton value={botMetadata?.meetingLink}>
							{({ copied, copy }) => (
								<Tooltip
									label={copied ? 'Copied' : `Copy ${botMetadata?.meetingLink}`}
									withinPortal
									withArrow
									position='top'
									zIndex={10000}
								>
									<FontAwesomeIcon
										icon={faLink}
										onClick={copy}
										style={{ cursor: 'pointer' }}
									/>
								</Tooltip>
							)}
						</CopyButton>
					)}
				</td>
				<td>
					<Group position={'right'}>
						<Menu position='left' withArrow>
							<Menu.Target>
								<ActionIcon>
									<FontAwesomeIcon icon={faEllipsisVertical} />
								</ActionIcon>
							</Menu.Target>
							<Menu.Dropdown>
								{collectionsEnabled &&
								!isOnCollectionsPage &&
								!isScheduledMeeting ? (
									<Menu.Item
										icon={<FontAwesomeIcon icon={faPlus} />}
										onClick={() => {
											setAddMeetingToCollectionModalOpened(true);
											setMeetingToAdd(meeting);
										}}
										color='gray'
									>
										Add to a collection
									</Menu.Item>
								) : null}
								{collectionsEnabled &&
								isCollectionOwner &&
								isOnCollectionsPage &&
								!isScheduledMeeting &&
								!viewingArchived ? (
									<Menu.Item
										color='gray'
										icon={<FontAwesomeIcon icon={faTrash} />}
									>
										<Text
											color={'gray'}
											onClick={() =>
												removeMeetingFromCollection(collection, id)
											}
										>
											Remove from collection
										</Text>
									</Menu.Item>
								) : null}
								{(!isOnCollectionsPage ||
									(isOnCollectionsPage &&
										collection.name === SpecialCollections.Archived)) &&
								!isScheduledMeeting ? (
									<Menu.Item
										onClick={(e) => {
											e.stopPropagation();
											isArchived
												? handleUnarchiveMeeting(meeting, index)
												: handleArchiveMeeting(meeting, index);
										}}
										color='gray'
										icon={
											<FontAwesomeIcon
												style={{
													cursor: 'pointer',
												}}
												icon={faBoxArchive}
											/>
										}
									>
										{isArchived ? 'Unarchive' : 'Archive'}
									</Menu.Item>
								) : null}
								{sharingEnabled && isMeetingHostOrPM && !isScheduledMeeting ? (
									<Menu.Item
										color='gray'
										icon={<FontAwesomeIcon icon={faShare} />}
										onClick={() => handleShare(meeting, index)}
									>
										Share
									</Menu.Item>
								) : null}
								<Menu.Item
									icon={<FontAwesomeIcon icon={faPaperclip} />}
									color='gray'
								>
									<CopyButton
										value={`${window.location.origin}/meetings/${friendlyID}`}
										timeout={2000}
									>
										{({ copied, copy }) => (
											<Text color={copied ? 'teal' : 'gray'} onClick={copy}>
												{copied ? 'Copied' : 'Copy meeting link'}
											</Text>
										)}
									</CopyButton>
								</Menu.Item>
								{isMeetingHostOrPM && !botDontJoin ? (
									<Menu.Item
										icon={<FontAwesomeIcon icon={faFileInvoice} />}
										color='gray'
										onClick={() =>
											handleRecapOnly({
												recapOnly,
												index,
												meetingID: id,
												organizationID: organizationID,
											})
										}
									>
										{recapOnly ? 'Change to recording' : 'Change to recap only'}
									</Menu.Item>
								) : null}
								{isMeetingHostOrPM && isScheduledMeeting ? (
									<Menu.Item
										color='gray'
										icon={<FontAwesomeIcon icon={faUserRobot} />}
										onClick={() =>
											handleBotJoin({
												index,
												botDontJoin,
												botMetadata,
												id,
												organizationID,
											})
										}
									>
										{botDontJoin ? 'Change to join' : "Change to don't join"}
									</Menu.Item>
								) : null}
								{isScheduledMeeting &&
									isMeetingHostOrPM &&
									!isCalendarEvent && (
										<Menu.Item
											color={'gray'}
											icon={<FontAwesomeIcon icon={faEdit} />}
										>
											<Text onClick={() => handleUpdateMeeting(meeting)}>
												Update
											</Text>
										</Menu.Item>
									)}
								{isMeetingHostOrPM ? (
									<Menu.Item
										color='gray'
										icon={<FontAwesomeIcon icon={faTrash} />}
									>
										<Text
											color={'gray'}
											onClick={() => handleDelete(id, organizationID)}
										>
											Delete
										</Text>
									</Menu.Item>
								) : null}
							</Menu.Dropdown>
						</Menu>
					</Group>
				</td>
			</tr>
		);
	});

	return (
		<>
			<AttachmentsModal
				viewingAttachments={viewingAttachments}
				setViewingAttachments={setViewingAttachments}
				meeting={viewingMeeting}
			/>
			{/*Meetings List View*/}
			{fetchingMeetings ? (
				<Table captionSide='bottom' striped highlightOnHover>
					<thead className={classes.header}>{ths}</thead>
					<tbody>
						{Array.from({ length: 20 }, (_, index) => (
							<TableRowSkeleton key={index} colSpan={11} height={'42.50px'} />
						))}
					</tbody>
				</Table>
			) : meetings.length ? (
				<Paper radius='md' shadow='md' withBorder>
					<Table captionSide='bottom' striped highlightOnHover>
						<thead className={classes.header}>{ths}</thead>
						<tbody>{rows}</tbody>
					</Table>
				</Paper>
			) : (
				<Introduction segmentValue={segmentValueProp} />
			)}
		</>
	);
}
