import { Paper, Stack, Switch, Text, Title } from '@mantine/core';
import { styles } from '../../styles';
import { useState } from 'react';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../../helpers/notifications';
import { logger } from '../../../../../helpers/logger';
import useUserSettings from '../../../../../customHooks/useUserSettings';

interface SettingCardProps {
	settingKey: string;
	label: string;
	description: string;
	successMessage: string;
	failureMessage: string;
	sharingOptionText?: string;
	defaultOptionText?: string;
}

export default function UserSettingSwitch({
	settingKey,
	label,
	description,
	successMessage,
	failureMessage,
	sharingOptionText,
	defaultOptionText,
}: SettingCardProps) {
	const { classes } = styles();
	const { userSettings, updateUserSettings } = useUserSettings();
	const [checked, setChecked] = useState<boolean>(userSettings?.[settingKey]);

	const handleSettingChange = async (value: boolean) => {
		try {
			setChecked(value);
			const res = await updateUserSettings([
				{
					key: settingKey,
					value,
				},
			]);
			const details = res[0];
			showSuccessNotification({
				message: successMessage,
			});
		} catch (error) {
			logger('error', `error changing ${settingKey}`, error);
			showFailureNotification({
				message: failureMessage,
			});
		}
	};

	return (
		<Paper className={classes.card} shadow='xs' p='md' radius={'md'}>
			<Stack>
				<Switch
					labelPosition='left'
					label={
						<Title order={4} color='primary-text'>
							{label}
						</Title>
					}
					description={
						<Text style={{ cursor: 'default' }}>
							{description}
							{sharingOptionText && (
								<>
									{' '}
									<Text span fs='italic'>
										{sharingOptionText}
									</Text>
									. Otherwise, the default will be{' '}
									<Text span fs='italic'>
										{defaultOptionText}
									</Text>
									.
								</>
							)}
						</Text>
					}
					size='sm'
					w={'100%'}
					width={'100%'}
					mb={'lg'}
					checked={checked}
					onChange={(e) => handleSettingChange(e.currentTarget.checked)}
					styles={{
						body: {
							width: '100%',
							justifyContent: 'space-between',
							flex: 1,
						},
					}}
				/>
			</Stack>
		</Paper>
	);
}
